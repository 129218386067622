// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"


type DataProps = {
  site: {
    buildTime: string
  }
}

const Community: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Awesome Code Review Community" location={location}>
    <Seo title="Code Review Community" />
    <div className="code-review-community">  
      <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
        <div className="col-md-6 px-0">
          <h1 className="display-4 font-italic">Join the Awesome Code Review Community</h1>
          <p className="lead my-3">The awesome code review community is a place for thoughtful conversations around how to best
      co-create high-quality software.</p>
          <p className="lead mb-0">     The code review community is for dedicated developers who want to improve
      how they <em>build software as a team.</em> </p>
        </div>
      </div>
    </div>
  

    <div className="container community-pricing">
      <div className="card-deck mb-3 text-center">
        <div className="card col-4 mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Free</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$0 <small className="text-muted">/ mo</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Bi-weekly email</li>
              <li>Access to selected member-only articles</li>
            </ul>
            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Sign up for free</button>
          </div>
        </div>
        <div className="card col-4 mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Monthly</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$15 <small className="text-muted">/ month</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Bi-weekly email</li>
              <li>Access to the Discord channel</li>
              <li>Access to all member-only articles</li>
              <li>Courses & workshops</li>
              <li>Live events & meetups</li>
            </ul>
            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Join monthly</button>
          </div>
        </div>
        <div className="card col-4 mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Yearly</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$149 <small className="text-muted">/ year</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
            <li>Bi-weekly email</li>
              <li>Access to the Discord channel</li>
              <li>Access to all member-only articles</li>
              <li>Courses & workshops</li>
              <li>Live events & meetups</li>
              <li>All past video recordings</li>
            </ul>
            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Join yearly</button>
          </div>
        </div>
      </div>
    </div>

    <p>
      You're currently on the page "{path}" which was built on{" "}
      {data.site.buildTime}.
    </p>
  </Layout>
)

export default Community

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
